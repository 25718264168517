.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
}
.wrapper {
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading {
  font-size: 3rem;
  font-weight: bold;
}
.Card {
  display: flex;
  padding: 10px;
  margin: 10px;
}
.swiperContainer{
  max-height: 100%;
  height:95%;
  width: 100%;
  display: flex;
  background-color:grey;
  padding:20px;
  margin: 10px;
  box-sizing: border-box;
  scroll-snap-align: center;
}
.swiper{
  height: 100%;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: both mandatory;
  height: 60%;
}
.swiperContent{
  width: 50%;
  background-color: grey;
  /* height: 100%; */
  padding :40px;
}
.heading{
  font-size: 2rem;
  font-weight: bold;
  color: white;
}
.languages{
  display: flex;
}
.piles{
  border: 1px solid #63fbd7;
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 10px;
}
.discription{
  color: white;
  font-size: 1rem;
  margin: 10px 0px;
  word-spacing: 4px;
  line-height: 2rem;
  /* letter-spacing: 2px; */
}
.link{
  font-style: italic;
  color: #63fbd7;
  font-size: 1rem;
  margin: 10px 0px;
}
 
.iframe{
  height: 100%;
  width: 50%;
  background-color: pink;
  position: relative;
}
.iframe::after{
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color:grey;
  opacity: 0.5;
  z-index: 99;
}
.iframe iframe{
  height: 100% ;
  width: 100%;
}


.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  scroll-snap-align: center;
}
.wrapper {
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.heading {
  font-size: 3rem;
  font-weight: bold;
}
.bio {
  margin-top: 20px;
  letter-spacing: 2px;
  width: 70%;
}
.technology {
  margin-top: 20px;
  display:flex;
}
.techIcons {
  margin-right: 10px;
  color: #ff0000 !important;
}
@media screen and (max-width: 600px) {
  .wrapper {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .heading {
    font-size: 3rem;
    font-weight: bold;
  }
  .bio {
    margin-top: 20px;
    letter-spacing: 2px;
    font-size: 1.5rem;
    width: 100%;
  }
  .technology {
    margin-top: 20px;
  }
  .techIcons {
    margin-right: 10px;
    color: #ff0000 !important;
  }
}

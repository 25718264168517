.container {
  /* height: 100vh; */
  width: 100vw;
  display: flex;
  justify-content: center;
  scroll-snap-align: center;
}
.wrapper {
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.heading {
  font-size: 3rem;
  font-weight: bold;
}
.Card {
  display: flex;
  padding: 10px;
  margin: 10px;

}
.nameCard {
  width: fit-content;
  border-left: 4px solid grey;
  /* background-color: green; */
  height: fit-content;
}
.companyDiv{
  display:flex;
  align-items: center;
}
.companyLogo{
  width: 80px;
  height:80px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
}
.companyPill {
  font-size: 1.2rem;
  padding: 10px 20px;
  margin: 5px 0px;
  cursor: pointer;
  /* background-color: red; */
}
.detailCard {
  flex: 1;
  height: 100%;
  padding: 5px 20px;
  box-sizing: border-box;
}
.active {
  color: #63fbd7;
}
.role {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
}
.name {
  color: #63fbd7;
}
.duration {
  color: grey;
  letter-spacing: 3px;
  margin-top: 10px;
}
.points {
  margin: 10px;
}
.point {
  margin: 20px;
}

@media screen and (max-width: 600px) {
  .wrapper {
    width: 85%;
  }
  .Card {
    display: flex;
    flex-direction: column;
  }
  .nameCard {
    border: none;
    display: flex;
    border-top: 4px solid grey;
    overflow-x: scroll;
  }
  .companyPill {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin: 5px 0px;
    cursor: pointer;
    /* background-color: red; */
  }
  .detailCard {
    margin: 0px;
    padding: 10px 0px;
  }
  .role {
    font-size: 2rem;
    flex-direction: column;
  }
  .name {
    color: #63fbd7;
    font-size: 2rem;
  }
  .duration {
    color: grey;
    letter-spacing: 3px;
    margin-top: 10px;
  }
  .points {
    margin: 10px 0px;
  }
  .point {
    margin: 20px 0px;
    font-size: 1.5rem;
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0px;
  margin: 0px;
  font-family: "Oxygen", sans-serif;
  /* background-color: black; */

  color: #c4c4c4;
  /* cursor: none !important; */
}
body {
  background-color: rgb(16, 16, 26);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-image: 
    radial-gradient(white 1px, transparent 1px),
    linear-gradient(135deg, #ffffff, #2cf4cf);
  background-size: 10px 10px, cover;

}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.App{
  scroll-snap-type: both mandatory;
  overflow-x: hidden;
  z-index: 1;
}
.App div{
  scroll-snap-align: center;
}
a {
  text-decoration: none;
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: #555;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
  background: #63fbd7;
}

@media screen and (max-width: 600px) {
  .cursor {
    display: none;
  }
  * {
    font-size: 10px;
  }
  .column {
    flex-direction: column !important;
  }
}

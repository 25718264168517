.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0px;
  left: 5%;
  align-items: center;
  /* scroll-snap-align: center; */
}
.container2 {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0px;
  right: 5%;
  align-items: center;
}
.FontAwesomeIcon {
  margin: 15px 0px;
}
.verticalStick {
  width: 1px;
  height: 10vh;
  background-color: #fff;
  margin-top: 50px;
}
.verticalText {
  letter-spacing: 4px;
  writing-mode: vertical-lr;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
}
.verticalText:hover {
  color: #63fbd7;
  transform: scale(1.2);
  transition-duration: 1s;
}
.socialIcon {
  margin: 15px 0px;
  transform: scale(1.5);
}
@media screen and (max-width: 600px) {
  .container {
    display: none;
  }
  .container2 {
    display: none;
  }
}

.container {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
  margin-top:10vh;
}
.subContainer {
  width: 75%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.heroText {
  height: 100%;
  width: 50%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.introText {
  font-size: 1.4rem;
  color: #63fbd7;
}
.name {
  font-size: 3.5rem;
  font-weight: bold;
}
.subHeading {
  font-size: 3rem;
  font-weight: bold;
  color: grey;
}
.bio {
  width: 75%;
  margin-top: 30px;
}

.connectionButton {
  border: none;
  /* border: 2px solid #63fbd7; */
  width: fit-content;
  /* padding: 20px 40px; */
  background-color: transparent;
  color: #63fbd7;
  margin-top: 30px;
  font-weight: bold;
  letter-spacing: 3px;
  position: relative;
}
.connectionButton::after {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 0px;
  background-color: wheat;
  height: 1px;
  width: 65%;
  margin-top: 10px;
  cursor: pointer;
}
.connectionButton:hover {
  cursor: pointer;
}
.connectionButton:hover::after {
  width: 100%;
  transition-duration: 1s;
  cursor: pointer;
}
.coffee {
  margin-left: 10px;
}
.heroImageContainer {
  width: 50%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.HeroImage {
  width: 90%;
  aspect-ratio: 1/1;
  /* background-color: grey; */
  z-index: 50;
  position: relative;
}
.HeroImage::after {
  content: "";
  border: 2px solid #63fbd7;
  display: flex;
  height: 100%;
  width: 100%;
  transform: translate(30px, 30px);
  z-index: 49;
  mix-blend-mode: screen;
}
.HeroImage:hover::after {
  transform: translate(-30px, -30px);
  transition-duration: 1s;
}

.HeroImage img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 60;
}
@media screen and (max-width: 600px) {
  .container {
   margin-top: 0vh;
  }
  .subContainer {
    width: 85%;
    /* overflow-x: hidden; */
    flex-wrap: wrap;
  }
  .heroText {
    height: auto;
    margin-top: 150px;
    width: 100%;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: red; */
  }
  .heroImageContainer {
    width: 100%;
    height: auto;
    /* background-color: green; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:40px;
  }
  .bio {
    width: 85%;
    font-size: 1.5rem;
    letter-spacing: 2px;
  }
}
